body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-base);
  color: var(--color-white);
  width: 100%;
  /* background-color: red; */
}

:root {
  --color-primary: #e5202b;
  --color-on-primary: #ffffff;
  --color-background: #ffffff;
  --color-background-secondary: #e9eaed;
  --color-on-background: #27292a;
  --color-primary-rgb: 229, 32, 43;
  --color-on-primary-rgb: 255, 255, 255;
  --color-background-rgb: 255, 255, 255;
  --color-background-secondary-rgb: 233, 234, 237;
  --color-on-background-rgb: 39, 41, 42;
  --color-background-brightness: 255;
  --button-corner-radius: 0px;
  --input-corner-radius: 0px;
  --image-corner-radius: 0px;
  --font-family-base: "FF DIN Pro", sans-serif;
  --font-family-heading: "FF DIN Pro Condensed", sans-serif;
  --font-weight-base: 500;
  --font-weight-base-bold: bold;
  --font-weight-heading: 900;
  --font-style-base: normal;
  --font-style-heading: italic;
  --text-transform-base: none;
  --text-transform-heading: uppercase;
  --logo-width: 48px;
}
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

/* body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-base);
  color: var(--color-white);
} */

/* .btn {
  display: inline-block;
  transition: opacity 300ms ease-out, border-color 300ms ease-out,
    background-color 300ms ease-out, color 300ms ease-out;
  border-radius: 0;
  text-decoration: none;
  padding: 16px 42px;
  -webkit-clip-path: polygon(18px 0%, 100% 0%, calc(100% - 18px) 100%, 0% 100%);
  clip-path: polygon(18px 0%, 100% 0%, calc(100% - 18px) 100%, 0% 100%);
  cursor: pointer;
  font-size: 1rem;
  font-style: var(--font-style-base);
  font-weight: var(--font-weight-base-bold);
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -0.02em;
}

.btn-primary {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
}

.btn-secondary {
  background-color: var(--color-on-background);
  color: var(--color-background);
}

.btn-primary:hover {
  background-color: #e06d73;
}

.btn-secondary:hover {
  background-color: #6a6d6e;
} */

img {
  display: block;
  width: 100%;
  object-fit: cover;
}
