.logo {
  display: block;
  width: 100%;
  object-fit: cover;
}
.logo--small {
  width: 150px;
}

.logo--medium {
  width: 120px;
  height: 60px;
}

.logo--large {
  width: 350px;
  height: 130px;
}
