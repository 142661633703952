* {
  margin: 0;
  padding: 0;
}

.main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  color: var(--color-on-background);
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-heading);
  font-style: var(--font-style-heading);
  text-transform: var(--text-transform-heading);
  letter-spacing: -0.02em;
  font-size: 4.25rem;
  margin: 0;
}

.cta {
  gap: 1.2rem;
  margin-top: 20px;
}

.hero h5 {
  color: var(--color-on-background);
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-heading);
  font-style: var(--font-style-heading);
  text-transform: var(--text-transform-heading);
  letter-spacing: -0.02em;
  font-size: 3rem;
}

.background {
  width: 100vw;
}

@media (650px <= width <= 1023px) {
  .hero h1 {
    font-size: 6.25rem;
  }
  .hero h5 {
    font-size: 2.8rem;
  }
}

@media (476px <= width <= 649px) {
  .hero h1 {
    font-size: 5rem;
  }
  .hero h5 {
    font-size: 1.8rem;
  }
  .background {
    width: 100vw;
  }
}

@media (320px <= width <= 475px) {
  .hero h1 {
    font-size: 3.25rem;
  }
  .hero h5 {
    font-size: 1.4rem;
  }
  .video {
    display: none;
  }
  /* .main {
    background-image: url("../../assets/static.jpg");
  }
  .video {
    display: none;
  } */
}

@media (220px <= width <= 320px) {
  .hero h1 {
    font-size: 3.25rem;
  }
  .hero h5 {
    font-size: 1.4rem;
  }
  .video {
    display: none;
  }
  /* .main {
    background-image: url("../../assets/static.jpg");
    background-size: cover;
  }
  .video {
    display: none;
  } */
}
