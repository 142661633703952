.navbar {
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 2;
  background: transparent;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
  overflow-x: hidden !important;
}

.navbar.active {
  background: rgba(248, 246, 246, 0.849);
  align-items: center;
}

.logo:hover {
  transform: rotate(360deg) translateZ(0);
}

.left {
  align-content: center;
  display: flex;
  flex: 2;
  justify-content: space-evenly;
}
.left a {
  color: var(--color-on-background);
  font-size: 1.4rem;
}
.left a:hover {
  color: var(--color-primary);
  font-weight: bold;
}

.center {
  display: flex;
  justify-content: center;
  flex: 1;
}

.nav {
  list-style: none;
  display: flex;
  flex: 2;
  justify-content: space-evenly;
  align-items: center;
}
.nav a {
  text-decoration: none;
  color: var(--color-on-background);
  font-size: 1.4rem;
}

.nav a:hover {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav a.active {
  color: var(--color-primary);
}

.hamburger__show {
  display: none;
}
.hamburger {
  display: none;
}

@media screen and (max-width: 940px) {
  .navbar {
    width: 100vw;
  }
  .navbar.active {
    background-color: white;
  }

  .left {
    display: none;
  }

  .hamburger__show {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
  }

  .nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    /* top: 60px; */
    top: 0px;
    right: -400px;
    width: 300px;
    height: 400px;
    background: white;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 1.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }
  .nav li {
    padding: 10px;
  }
  .nav button {
    margin-top: 10px;
  }
  .nav.active {
    right: 0;
  }

  .hamburger {
    display: flex;
    margin-right: 2rem;
    z-index: 5;
  }
  .center {
    display: flex;
    justify-content: center;
    flex: 0;
    margin-left: 1.5rem;
  }
}

@media screen and (max-width) {
  .nav {
    font-size: 4rem;
  }
  .navbar.active {
    background: rgba(248, 246, 246, 0.849);
  }
}
