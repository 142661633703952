.marquee {
  --gap: 1rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  font-size: 80px;
  font-family: "FF DIN Pro Condensed", sans-serif;
  width: 100%;
  mask-image: linear-gradient(
    to right,
    transparent,
    black 10%,
    black 90%,
    transparent
  );
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
  animation: scroll 10s linear infinite;
  padding: 25px 0;
  list-style: none;
  font-size: 80px;
  margin: 0;
  font-family: "FF DIN Pro Condensed", sans-serif;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20%);
  }
}

/* Pause on hover */
.marquee--hover-pause:hover .marquee__content {
  animation-play-state: paused;
}

@media (1024px <= width <= 2060px) {
  .marquee__content li {
    font-size: 3.6rem;
  }
}
@media (320px <= width <= 427px) {
  .marquee__content li{
    font-size: 1.4rem;
  }
}

@media (427px <= width <= 770px) {
  .marquee__content li{
    font-size: 1.7rem;
  }
}

.marquee__content li {
  white-space: nowrap;
  flex-shrink: 0;
  padding: 0 calc(var(--gap) / 2);
}
