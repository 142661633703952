/* .page {
  height: 100vh;
  background-color: var(--color-on-primary);
  background-size: 5px 5px;
  background-image: radial-gradient(
    var(--color-background-secondary) 1%,
    transparent 1%
  ); */
/* -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 64px),
    calc(100% - 64px) 100%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 64px),
    calc(100% - 64px) 100%,
    0 100%
  );
  color: var(--color-on-background); */

.aboutContainer {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  justify-content: center;
  /* overflow: auto; */
  position: relative;
  /* height: 100vh; */
  background: var(--color-white);
  color: var(--color-grey);
  width: 75%;
  margin: 0 auto;
  padding: 10px;
  gap: 2rem;
  flex: 1 100%;
  border-radius: 10px;
  overflow-x: hidden;
}

.aos-init:not(.aos-animate):after {
  position: fixed;
}

.text__zone {
  margin-top: 100px;
  flex: 2;
  text-align: center;
  justify-content: center;
}
.text__zone h1 {
  margin-bottom: 30px;
}
.text__zone p {
  line-height: 1.5rem;
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 60px;
}
.about__card {
  background: var(--color-on-background);
  color: var(--color-on-primary);
  border: 1px solid transparent;
  /* border-radius: 1rem; */
  -webkit-clip-path: polygon(
    48px 0%,
    100% 0,
    100% calc(100% - 48px),
    calc(100% - 48px) 100%,
    0 100%,
    0% 48px
  );
  clip-path: polygon(
    48px 0%,
    100% 0,
    100% calc(100% - 48px),
    calc(100% - 48px) 100%,
    0 100%,
    0% 48px
  );
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
  line-height: 32px;
}

.about__card:hover {
  background: var(--color-on-primary);
  color: var(--color-on-background);
  border-color: var(--color-primary);
  cursor: default;
}
.about__icon {
  color: var(--color-primary);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 1rem;
}

.about__card small {
  font-size: 0.9rem;
  color: var(--color-light);
}

.tech {
  padding: 10px;
  margin-top: 60px;
  display: list-item;
}
.tech ul {
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 200px));
  gap: 0px 10px;
  padding: 0px;
  margin: 20px 0px 0px;
  overflow: hidden;
  list-style: none;
  text-align: left;
  justify-content: center;
}
.tech li {
  margin-bottom: 10px;
}
.imageSection {
  display: flex;
  margin-top: 400px;
  flex: 1;
  background-size: cover;
  object-fit: fill;
  transition: transform 1s;
}

.log:hover {
  transform: rotate(360deg) translateZ(0);
}

/* @media screen and (max-width: 1440px) {
  .tech ul {
    text-align: center;
    justify-content: center;
  }
} */

@media (320px <= width <= 550px) {
  .about__cards {
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .about__card {
    padding: 2rem;
    text-align: center;
  }
  .aboutContainer {
    width: 90%;
  }
}

@media (320px <= width <= 925px) {
  .imageSection {
    margin-top: 70px;
    justify-content: center;
  }
}
